import React, { Component } from "react"
import Header from "../components/basics-compoment/Header"
import Footer from "../components/basics-compoment/Footer"
import "../myStyle.css"
import HelmetCompoment from "../components/basics-compoment/HelmetCompoment"
import TextStyle from "../components/style-compoment/TextStyle"

// markup
class CGU extends Component {

  render () {

    return (

      <React.Fragment>

        <HelmetCompoment></HelmetCompoment>

        <Header page="CGU"></Header>
        <div className="container">
          <TextStyle
            class="title big"
            french="Conditions générales d'utilisation"
            english="Conditions générales d'utilisation"
            spanish="Conditions générales d'utilisation"
          ></TextStyle>
        </div>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="1. DONNÉES PERSONNELLES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Les données personnelles recueillies sur le site résultent de la communication volontaire de vos informations (nom, prénom, adresse, numéro de téléphone, adresse de courrier électronique,…) lors de l’envoi d’un message via le formulaire dédié, d’un dépôt de candidature ou de la soumission d’un commentaire sur le site. Elles sont uniquement destinées à un usage interne, au traitement de votre demande et à la collecte de statistiques d’utilisation du site (cf. article 5). Ces données seront conservées pendant une durée maximum de 3 ans, sauf législation contraire imposant la conservation de ces données sur une durée supérieur.",
          <br></br>, <br></br>,
          "Il est interdit aux utilisateurs du site agence-rhapsodie.fr, professionnels ou particuliers, de publier des données personnelles hors des champs spécifiquement prévus à cet effet. Dans ce cadre, Rhapsodie ne peut être tenu pour responsable de la divulgation à des tiers d’informations personnelles ayant été saisies hors de ces champs.",
          <br></br>, <br></br>,
          "En aucun cas les données personnelles recueillies via ce site ne pourront être cédées ou vendues à des tiers.",
          <br></br>, <br></br>,
          "En application de la loi Informatique et Libertés du 6 janvier 1978 modifiée par la loi du 9 août 2004 et du Règlement Général sur la Protection des Données, entrée en vigueur le 25 mai 2018, vous disposez d’un droit d’accès, de rectification, de modification et de suppression concernant les données qui vous concernent (cf. article 5)."]}
        ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="2. FINALITÉ DE LA COLLECTE DE VOS DONNÉES PERSONNELLES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Les données personnelles recueillies par le biais de ce site seront traitées dans l’une ou plusieurs des finalités suivantes :",
          <br></br>, <br></br>,
          "– Le traitement commercial de votre demande (commande, établissement d’un devis, demande de renseignements complémentaires…) ;", <br></br>,
          "– Le traitement de votre candidature à un poste proposé ou de votre candidature spontanée ;", <br></br>,
          "– La mise à disposition de contenus ;", <br></br>,
          "– Le traitement des demandes adressées par les visiteurs ;", <br></br>,
          "– L’évaluation statistique de la fréquentation du site ;", <br></br>,
          "– La gestion des opérations de communication pour lesquels vous aurez préalablement donné votre accord ;",
          <br></br>, <br></br>,
          "S’agissant de nos clients, le traitement et la conservation de leurs données à caractère personnel résultent d’une exigence légale ou réglementaire en matière contractuelle. Pour les autres finalités, le traitement et la conservation des données relèvent du consentement donné par les visiteurs et contributeurs du site."]}
       ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="3. COMMUNICATION DE VOS DONNÉES PERSONNELLES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Dans le but de mener à bien toute action commerciale, de communication, d’information, de recrutement, ou de réponse à une question ou demande formulée via le site, les informations personnelles d’un visiteur ou contributeur pourront être transmises aux personnes ou prestataire suivants :",
          <br></br>, <br></br>,
          "– Les membres de l’agence Rhapsodie qui, en raison de leurs attributions, sont habilités à gérer ces traitements à des fins commerciales, de recrutement, de communication ou d’information des visiteurs dans le cadre d’une demande particulière;", 
          <br></br>, <br></br>,
          "– Les prestataires chargés de la maintenance et la gestion du site internet ou de la réalisation des opérations de traitements de Données à caractère personnel pour le compte de Rhapsodie qui sont également soumis à des obligations de confidentialité et de sécurité.", 
          <br></br>, <br></br>,
          "Comme précisé à l’article 1, en aucun cas les données personnelles recueillies via ce site ne pourront être cédées ou vendues à des tiers."]}
       ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="4. STOCKAGE ET PROTECTION DE VOS DONNÉES PERSONNELLES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Vos données personnelles collectées via ce site seront conservées pendant une durée maximum de 3 ans sauf obligations légales prolongeant ce délai.",
          <br></br>, <br></br>,
          "Les informations recueillies via les formulaires de contact de ce site sont enregistrées dans un fichier informatique sécurisé par Rhapsodie uniquement dans le but de pouvoir répondre à votre demande. Vous pouvez exercer votre droit d’accès, de suppression ou de rectification de vos données personnelles via le formulaire dont le lien est disponible à l’article 5.", 
          <br></br>, <br></br>,
          "Nous mettons tout en oeuvre pour protéger vos données personnelles et empêcher leur diffusion. Néanmoins, si un piratage venait à entraîner une fuite de données, le responsable du site s’engage à prévenir toute personne victime d’une violation de ses données à caractère personnel dans les plus brefs délais. Parallèlement, une notification documentée de cette violation sera également adressée à la CNIL dans un délai maximum de 72 heures après sa découverte."]}
       ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="5. DEMANDE D'ACCÈS, DE RECTIFICATION OU DE SUPPRESSION DE VOS DONNÉES PERSONNELLES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Si vous avez déjà laissé un message par le biais de notre formulaire de contact, vous nous avez communiqué des données personnelles (prénom, nom, adresse, adresse e-mail…). Vous pouvez demander à recevoir un fichier contenant toutes les données personnelles que nous possédons à votre sujet, incluant celles que vous nous avez fournies. Vous pouvez également demander la suppression des données personnelles vous concernant, via cette page."]}
       ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="6. POLITIQUE DE COOKIES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Pour faciliter la navigation, éviter les identifications récurrentes et offrir des conditions d’utilisation optimales, ce site peut installer dans votre ordinateur des cookies d’authentification qui inscrivent dans votre terminal de connexion des informations relatives à vos données de connexion. Ces cookies expirent au bout de 30 jours. Vous pouvez vous opposer à l’enregistrement de ces cookies par votre navigateur dès votre arrivée sur le site en cliquant sur le bouton “Je refuse” ou en sélectionnant les applications non souhaitées ou à tout moment lors de votre visite du site en cliquant sur le bouton “confidentialité” en bas à gauche de toutes les pages du site et en désélectionnant les cookies auxquels vous souhaitez vous opposer."]}
       ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="7. UTILISATION DE VOS DONNÉES PAR GOOGLE ANALYTICS"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Ce site utilise Google Analytics, un service d’analyse de site internet fourni par Google Inc. («Google»). Google Analytics utilise des cookies, qui sont des fichiers texte placés sur votre ordinateur, pour aider le site internet à analyser l’utilisation du site par ses utilisateurs. Les données générées par les cookies concernant votre utilisation du site (y compris votre adresse IP) seront transmises et stockées par Google sur des serveurs situés aux Etats-Unis.",
          <br></br>, <br></br>,
          "Google utilisera cette information dans le but d’évaluer votre utilisation du site, de compiler des rapports sur l’activité du site à destination de son éditeur et de fournir d’autres services relatifs à l’activité du site et à l’utilisation d’Internet. Google est susceptible de communiquer ces données à des tiers en cas d’obligation légale ou lorsque ces tiers traitent ces données pour le compte de Google, y compris notamment l’éditeur de ce site. Google ne recoupera pas votre adresse IP avec toute autre donnée détenue par Google.", 
          <br></br>, <br></br>,
          "Vous pouvez désactiver l’utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur. Cependant, une telle désactivation pourrait empêcher l’utilisation de certaines fonctionnalités de ce site. Vous pouvez vous opposer à l’utilisation de ces cookies en suivant la procédure indiqué dans l’article 6."]}
       ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="8. MISE À JOUR DE LA POLITIQUE DE GESTION DES DONNÉES PERSONNELLES"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["TRAINFAST se réserve le droit de mettre à jour la présente politique de gestion et d’utilisation des données à tout moment, pour répondre aux exigences du législateur. Tous les utilisateurs sont donc invités à visiter cette page lors de chaque consultation du site afin d’en prendre connaissance."]}
          cssStyle={{marginBottom: "80px"}}
       ></TextStyle>
        <Footer></Footer>
        </React.Fragment>

    )

  }

}

export default CGU

